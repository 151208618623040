import React from 'react';
import './comfooter.scss'
// import oswaplogo from '../assets/img/oswaplogo.png'

function ComfooterComponents() {
  return (
    <section className="comfooter">
      <div className='footer-info'>
        {/* <img className='footer-logo' src={oswaplogo} alt="logo" /> */}
        <div className='footer-right'>
          <a target="_blank" rel="noreferrer" className="hreflink" href="https://www.dlab.zone/">
            dLab
          </a>
          <a target="_blank" rel="noreferrer" className="hreflink" href="https://discord.gg/jh8QU5529X">
            Discord
          </a>
          <a target="_blank" rel="noreferrer" className="hreflink" href="https://twitter.com/OmnichainSwap">
            Twitter
          </a>
          <a target="_blank" rel="noreferrer" className="hreflink" href="https://github.com/The-dLab/">
            Github
          </a>
        </div>
      </div>
      <div className='bgimg'> </div>
    </section>
  )
}
export default ComfooterComponents
