import './main.scss';
import closed from '../../assets/img/closed.png';

function IndexPageComponents() {
  return (
    <div className="indexPage">
      <div className="leftInfo">
        <img src={closed} alt="" />
      </div>
    </div>
  );
}
export default IndexPageComponents;
