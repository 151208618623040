// @ts-nocheck
import React, { useEffect, useState } from 'react';
// import { Link } from 'react-router-dom';
import './comheader.scss'
import oswaplogo from '../assets/img/oswaplogo.png'


function ShowName() {
  const [showName, setShowName] = useState('NFT Aggregator');
  const changeName = () => {
    let cName = "NFT Aggregator"
    setInterval(() => {
      if (cName === 'NFT Aggregator') {
        cName = 'Built on LayerZero'
        setShowName('Built on LayerZero')
      } else {
        cName = 'NFT Aggregator'
        setShowName('NFT Aggregator')
      }
    }, 3000)
  }
  useEffect(() => {
    changeName();
  }, [])
  return <div className='comName titleName'>{showName}</div>
}
function Headercom() {
  return (
    <div className='headers'>
      <div className='headers-leftcontent'>
        <ShowName />
        {/* <div className='comName '>Built on LayerZero</div> */}
        <img className='logo' src={oswaplogo} alt="" />
        <span className='versionB'>beta</span>
      </div>
    </div>
  )
}

export default Headercom
