import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import IndexPageComponents from "./pages/index/main"
import Headercom from './components/comheader';
import ComfooterComponents from "./components/comfooter"
import './App.scss';

function App() {
  const resizeListener = () => {
    const designSize = 1920;
    const html = document.documentElement;
    const clientW = html.clientWidth;
    const htmlRem = (clientW * 100) / designSize;
    html.style.fontSize = `${htmlRem}px`;
  };

  useEffect(() => {
    resizeListener();
    window.addEventListener('resize', resizeListener);
    return () => window.removeEventListener('resize', resizeListener);
  }, []);



  return (
    <div className="App">
      <Router>
        <Headercom />
        <div className='mainContent'>
          <Routes>
            {/* Home */}
            <Route exact path="/" element={<IndexPageComponents />} />

          </Routes>
        </div>
        <ComfooterComponents />
      </Router>
    </div>
  );
}

export default App;

